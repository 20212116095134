import React, { Component } from 'react';
import Login from '../components/Login/Login';

class Home extends Component {
  render() {
    return (
      <div className="d-flex flex-column mx-auto col-lg-6">
        <Login />
      </div>
    )
  }
}

export default Home