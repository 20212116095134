import React, {Component} from 'react';
import BarcodeReader from '../../../components/BarcodeReader/BarcodeReader';
import xregexp from 'xregexp';

class EquipmentSN extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scanMode: ''
    }
  }

  toggleScanMode = (mode) => {
    this.setState({
      scanMode: mode
    })
  }

  handleEquipmentSerialNoScan = data => {
    if (data) {
      this.setSerial(data, this.checkEquipmentSerialNo(data))
    }
  }

  handleEquipmentSerialNoManually = event => {
    this.setSerial(event.target.value, this.checkEquipmentSerialNo(event.target.value))
  }

  setSerial = (equipmentSerialNo, equipmentSerialNoValid) => {
    this.props.setEquipmentSerialNo(equipmentSerialNo, equipmentSerialNoValid)
  }

  checkEquipmentSerialNo = serial => {
    if (this.props.type === 'TAIL_LIFT') {
      // eslint-disable-next-line
      const regexp = xregexp("^\\d{6}\$")
      return xregexp.test(serial, regexp)
    } else
      if (this.props.type === 'TMFL') {
        // eslint-disable-next-line
        const regexp = xregexp("(?i)^[a-z]{1}(D|S){0,1}[\\d]{5}(0A|2|2E|4|4A|4B|4E|5A|5B|5C|5D|5E|5F|6|8|8A|8B|8C|8D|8E|8F|8G|8H|8I|9|9A|9B|9C|9D|9E|9F|9G|9H)\$")
        return xregexp.test(serial, regexp)
      }
  }

  render() {
    return (
      <div className="d-flex flex-column align-items-start">
        <div className="d-flex flex-row align-items-center">
          <button className="btn btn-primary" onClick={() => this.toggleScanMode('barcode-equipment')}>
            SCAN BARCODE
          </button>
          <p className="p-0 m-0 ml-3" onClick={(e) => this.toggleScanMode('manual-equipment')}>Enter manually</p>
        </div>

        {(this.state.scanMode === 'barcode-equipment') && (
          <div className="d-flex flex-column mt-4 col-md-6">
            <BarcodeReader handleEquipmentSerialNoScan={this.handleEquipmentSerialNoScan} />
            <p>{this.props.equipmentSerialNo}</p>
          </div>)}

        {(this.state.scanMode === 'manual-equipment') && (
          <div>
            <input type="text" id="equipment-serial" className="form-control mt-4" placeholder="Enter serial number" onChange={this.handleEquipmentSerialNoManually} value={this.props.equipmentSerialNo} />
          </div>
        )}
        {this.props.equipmentSerialNoValid === false &&
          <p className="text-danger">Serial number not valid</p>
        }
        {this.props.equipmentSerialNoValid === true &&
          <p className="text-success">Serial number valid <span role="img" aria-label="ok">✅</span></p>
        }
      </div>
    )
  }
}

export default EquipmentSN