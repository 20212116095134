import React, { Component } from 'react';
import Modal from 'react-modal';
import './Login.scss';
import auth from '../../auth/authAWS'

class Login extends Component {
  constructor(props) {
    super(props);
    if (process.env.REACT_APP_OFFLINE === true) {
      this.state = {
        checkAccess: false,
        isLoggedIn: true,
        modalIsOpen: false,
        errorMessage: ''
      }
    }
    else {
      this.state = {
        checkAccess: true,
        isLoggedIn: false,
        modalIsOpen: false,
        errorMessage: ''
      }
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentWillMount() {
    console.log("Login.js: Check current login status..")

    auth.isLoggedIn().then((status) => {
      console.log('Login auth status:', status)
      if (status) {
        this.setState({checkAccess: false, isLoggedIn: true})
        console.log("Login.js: Login OK")
        setTimeout(() => {
          window.location.assign('/new')
        }, 1500)
      } else {
        this.setState({checkAccess: false, isLoggedIn: false})
        console.log("Login.js: Login not valid")
      }
    })
  }
  login = () => {
    auth.resetPin();
    auth.login();
  }
  logout = () => {
    auth.logout();
  }
  openModal = errorMessage => {
    this.setState({ modalIsOpen: true, errorMessage })
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessage: '' })
  }
  render() {
    if (this.state.checkAccess === true) {
      return (
        <div>
          <div className="d-flex justify-content-center">
            <div className="circle-loader small">
              <div></div>
            </div>
          </div>
          <p className="mt-0 mb-0 text-center">Logging you in</p>
        </div>
      )
    }
    else {
      return (
        <div className="">
          {this.state.isLoggedIn === true ?
            (<div>
              <p className="mt-4 mb-0 text-center">Logged in succesfully</p>
            </div>) :
            (<div>
              <p className="mt-4 mb-0 text-center">Login using Salesforce</p>
              <button type="submit" className="btn btn-primary btn-lg w-100 mt-4" onClick={() => this.login()}>LOG IN</button>
            </div>)}
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Error"
            className="Modal"
            overlayClassName="Overlay">
            <div className="error-header">
              <h3>Error</h3>
            </div>
            <div className="error-body">
              <p>{this.state.errorMessage}</p>
            </div>
            <div className="error-footer">
              <button className="btn btn-secondary" onClick={this.closeModal}>Ok</button>
            </div>
          </Modal>
        </div>
      )
    }
  }
}

export default Login