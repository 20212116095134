import React, { Component } from 'react';
import auth from '../auth/authAWS'

class HandleAuthCode extends Component {
  constructor(props) {
    super(props);
    auth.afterLogin();
  }
  
  render() {
    return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="circle-loader small">
          <div></div>
        </div>
      </div>
      <p className="mt-0 mb-0 text-center">Logging you in...</p>
    </div>)
  }
}

export default HandleAuthCode