import React, { Component } from 'react';
import Modal from 'react-modal';
import './NewComm.scss';
import taillift from '../assets/images/tail-lift.png';
import forklift from '../assets/images/moffett.png';
import volvo from '../assets/images/volvo.png';
import Form from './form/Form'
import Volvo from './form/Volvo'
import api from '../api/api';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';

class NewComm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: "",
      status: null,
      modalIsOpen: false,
      errorMessage: ''
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.getGroups()
  }

  getGroups = () => {
    api.get('/auth/me').then(data => {
      this.setState({ groups: data.data.groups })
      if (this.state.groups.length === 0) {
        this.setState({ status: 'No available groups. Please contact your system admininstrator' })
      }
    }).catch(error => {
      this.setState({ status: 'ERROR' })
      console.log(error.response.request)
      this.openModal('Cannot get groups: ' + error.response.request.statusText)
    })
  }

  groups = () => {
    return this.state.groups.split(";")
  }

  isAdmin = () => {
    return this.groups().includes("Admin")
  }

  isTailLift = () => {
    return this.groups().includes("Tail Lift")
  }

  isTMFL = () => {
    return this.groups().includes("Truck Mounted Forklift")
  }

  isVolvo = () => {
    return this.groups().includes("Volvo")
  }

  openModal = errorMessage => {
    this.setState({ modalIsOpen: true, errorMessage })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessage: '' })
  }

  render() {
    return (
      <div className="">
        <h3>Commission new gateway</h3>
        <br />
        <h4>Select product type</h4>
        <div className="d-flex flex-row type-choice-container">
          {(this.isAdmin() || this.isTailLift()) &&
            <NavLink to="/new/taillift/">
              <div className="d-flex type-choice">
                <div className="d-flex flex-column justify-content-center">
                  <img src={taillift} className="w-100" alt="Tail lift" />
                  <p className="text-center">TAIL LIFT</p>
                </div>
              </div>
            </NavLink>}

          {(this.isAdmin() || this.isTMFL()) &&
            <NavLink to="/new/tmfl/">
              <div className="d-flex type-choice">
                <div className="d-flex flex-column justify-content-center">
                  <img src={forklift} className="w-100" alt="Forklift" />
                  <p className="text-center">TRUCK MOUNTED FORKLIFT</p>
                </div>
              </div>
            </NavLink>}

          {(this.isAdmin() || this.isVolvo()) &&
            <NavLink to="/new/volvo/">
              <div className="d-flex type-choice">
                <div className="d-flex flex-column justify-content-center">
                  <img src={volvo} className="w-100" alt="Volvo" />
                  <p className="text-center">VOLVO</p>
                </div>
              </div>
            </NavLink>}
        </div>
        <Route path="/new/taillift/" render={() => <Form type="TAIL_LIFT" />} />
        <Route path="/new/tmfl/" render={() => <Form type="TMFL" />} />
        <Route path="/new/volvo/" component={Volvo} />

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Error"
          className="Modal"
          overlayClassName="Overlay">
          <div className="error-header">
            <h3>Error</h3>
          </div>
          <div className="error-body">
            <p>{this.state.errorMessage}</p>
          </div>
          <div className="error-footer">
            <button className="btn btn-secondary" onClick={this.closeModal}>Ok</button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default NewComm