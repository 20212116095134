import React, { Component } from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import api from '../api/api';
import Licenses from './profile/Licenses';
import ServiceDescription from './profile/ServiceDescription';
import SignOut from '../views/SignOut';

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groups: '',
      username: '',
      modalIsOpen: false,
      errorMessage: ''
    }
  }

  componentWillMount() {
    this.getUserData()
  }

  getUserData = () => {
    api.get('/auth/me').then(data => {
      const groups = data.data.groups.replace(/;/g, ', ')
      this.setState({ username: data.data.email, groups })
      if (groups.length === 0) {
        this.setState({ status: 'No available groups. Please contact your system admininstrator' })
      }
    }).catch(error => {
      this.setState({ status: 'ERROR' })
      console.log(error.response.request)
      this.openModal('Cannot get user data: ' + error.response.request.statusText)
    })
  }

  openModal = errorMessage => {
    this.setState({ modalIsOpen: true, errorMessage })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessage: '' })
  }

  render() {
    return (
      <div className="">
        <div className="text-center">
          <div className="pb-1">{this.state.username}</div>
          <div className="small">{this.state.groups}</div>
        </div>
        <div className="py-5">
          <NavLink to="/profile/licenses">
            <p className="text-center">OPEN SOURCE LICENSES</p>
          </NavLink>
          <a className="text-center" rel="noopener noreferrer" target="_blank" href="https://www.hiab.com/en/pages/privacy-policy">
            <p className="text-center">PRIVACY POLICY</p>
          </a>
          <NavLink to="/profile/service-description">
            <p className="text-center">SERVICE DESCRIPTION</p>
          </NavLink>
          <NavLink to="/signout">
            <p className="text-center">LOG OUT</p>
          </NavLink>
        </div>

        <Route path="/profile/licenses" component={Licenses} />
        <Route path="/profile/service-description" component={ServiceDescription} />
        <Route path="/signout/" component={SignOut} />

        <div className="text-center">
          <div className="small">Build <b>{process.env.REACT_APP_BUILD_TIME}</b></div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Error"
          className="Modal"
          overlayClassName="Overlay">
          <div className="error-header">
            <h3>Error</h3>
          </div>
          <div className="error-body">
            <p>{this.state.errorMessage}</p>
          </div>
          <div className="error-footer">
            <button className="btn btn-secondary" onClick={this.closeModal}>Ok</button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Profile