import React from 'react';
import licensesJson from '../../licenses.json'
import './Licenses.scss'

function Licenses() {
  const file = licensesJson
  const licenses = Object.entries(file).map(([key, value]) => ({ key, value }))

  return (
    <div>
      <h3>Open source licenses</h3>
      <br/>
      {licenses.map((license, index) =>
        <div className="license" key={index}>
          <a target="_blank" rel="noopener noreferrer" href={license.value.repository}>{license.key}</a>
          <span> {license.value.licenses}</span>
        </div>
      )}
    </div>
  )
}

export default Licenses