const input4 = {
	"domain_label": "liftCapacity",
	"ui_label": "Lift Capacity",
	"WALTCO":
	{
		"HD_COLUMN_LIFT": {
			"unit": "LBS",
			"values": [3500, 4500, 5500, 6600]
		},
		"LD_COLUMN_LIFT": {
			"unit": "LBS",
			"values": [1250, 1600, 2000, 3000]
		},
		"FLIPAWAY": {
			"unit": "LBS",
			"values": [2000, 2500, 3000, 3300, 4000, 5000]
		},
		"SLIDER_WITH_AUXILIARY_MOTOR": {
			"unit": "LBS",
			"values": [3300, 4400, 5500]
		}
	},
	"DEL": {
		"LD_COLUMN_LIFT": {
			"unit": "KG",
			"values": [500, 750, 1000, 1500]
		},
		"FLIPAWAY": {
			"unit": "KG",
			"values": [500, 1000, 1500]
		},
	},
	"ZEPRO": {
		"CANTILEVER": {
			"unit": "KG",
			"values": [450, 500, 600, 750, 1000, 1500, 2000, 2500, 3000]
		},
		"FLIPAWAY": {
			"unit": "KG",
			"values": [1000, 1500, 2000]
		},
		"SLIDER_WITH_TILT": {
			"unit": "KG",
			"values": [750, 1000, 1500, 2000, 2500]
		}
	},
	"3RD_PARTY":
	{
		"HD_COLUMN_LIFT": {
			"unit": "LBS",
			"values": [3500, 4500, 5500, 6600]
		},
		"LD_COLUMN_LIFT": {
			"unit": "LBS",
			"values": [1250, 1600, 2000, 3000]
		},
		"FLIPAWAY": {
			"unit": "LBS",
			"values": [2000, 2500, 3000, 3300, 4000, 5000]
		},
		"SLIDER_WITH_AUXILIARY_MOTOR": {
			"unit": "LBS",
			"values": [3300, 4400, 5500]
		}
	}
}

const input3 = {
	"domain_label": "temperatureSensor",
	"ui_label": "Temperature Sensor",
	"TAIL_LIFT": [
		{
			"name": "Yes",
			"value": true
		},
		{
			"name": "No",
			"value": false
		}
	]
}

const input2 = {
	"domain_label": "inputFunctionality",
	"ui_label": "Input functionality",
	"WALTCO": {
		"APLICOM_A9": [
			{
				"name": "HD Column lift",
				"value": "HD_COLUMN_LIFT",
				"sort": 0,
				"next": input4
			},
			{
				"name": "LD Column Lift",
				"value": "LD_COLUMN_LIFT",
				"sort": 1,
				"next": input4
			},
			{
				"name": "Flipaway",
				"value": "FLIPAWAY",
				"sort": 2,
				"next": input4
			},
			{
				"name": "Slider with aux motor",
				"value": "SLIDER_WITH_AUXILIARY_MOTOR",
				"sort": 3,
				"next": input4
			}
		],
		"TRACKUNIT": [
			{
				"name": "WDV Basic",
				"value": "WDV_BASIC",
				"sort": 0,
			},
			{
				"name": "WDV Pressure",
				"value": "WDV_PRESSURE_PACKAGE",
				"sort": 1,
			},
			{
				"name": "HLF Pressure",
				"value": "HLF_PRESSURE_PACKAGE",
				"sort": 2,
			},
			{
				"name": "GT Pressure",
				"value": "GT_PRESSURE_PACKAGE",
				"sort": 3,
			}
		]
	},
	"DEL": {
		"APLICOM_A9": [
			{
				"name": "LD Column Lift",
				"value": "LD_COLUMN_LIFT",
				"sort": 0,
				"next": input4
			},
			{
				"name": "Flipaway",
				"value": "FLIPAWAY",
				"sort": 1,
				"next": input4
			}
		],
		"TRACKUNIT": [
			{
				"name": "Column Lift Pressure",
				"value": "COLUMN_LIFT_PRESSURE",
				"sort": 0,
			},
			{
				"name": "Column Lift Basic",
				"value": "COLUMN_LIFT_BASIC",
				"sort": 1,
			}
		]
	},
	"3RD_PARTY": {
		"APLICOM_A9": [
			{
				"name": "HD Column lift",
				"value": "HD_COLUMN_LIFT",
				"sort": 0,
				"next": input4
			},
			{
				"name": "LD Column Lift",
				"value": "LD_COLUMN_LIFT",
				"sort": 1,
				"next": input4
			},
			{
				"name": "Flipaway",
				"value": "FLIPAWAY",
				"sort": 2,
				"next": input4
			},
			{
				"name": "Slider with aux motor",
				"value": "SLIDER_WITH_AUXILIARY_MOTOR",
				"sort": 3,
				"next": input4
			}
		],
		"TRACKUNIT": [
			{
				"name": "WDV Basic",
				"value": "WDV_BASIC",
				"sort": 0,
			},
			{
				"name": "WDV Pressure",
				"value": "WDV_PRESSURE_PACKAGE",
				"sort": 1,
			},
			{
				"name": "HLF Pressure",
				"value": "HLF_PRESSURE_PACKAGE",
				"sort": 2,
			},
			{
				"name": "GT Pressure",
				"value": "GT_PRESSURE_PACKAGE",
				"sort": 3,
			}
		]
	},
	"ZEPRO": {
		"APLICOM_A9": [
			{
				"name": "Cantilever",
				"value": "CANTILEVER",
				"sort": 0,
				"next": input4
			},
			{
				"name": "Flipaway",
				"value": "FLIPAWAY",
				"sort": 1,
				"next": input4
			},
			{
				"name": "Slider with tilt",
				"value": "SLIDER_WITH_TILT ",
				"sort": 2,
				"next": input4
			}
		],
		"TRACKUNIT": [
			{
				"name": "Cantilever Lift Basic",
				"value": "CANTILEVER_LIFT_BASIC",
				"sort": 0,
			},
			{
				"name": "Slider Lift Basic",
				"value": "SLIDER_LIFT_BASIC",
				"sort": 1,
			}
		]
	},
	"TMFL": [
		{
			"name": "Kohler Engine",
			"value": "KOHLER_ENGINE",
			"sort": 0
		},
		{
			"name": "Kubota Engine",
			"value": "KUBOTA_ENGINE",
			"sort": 1
		}
	]
}

const input1 = {
	"domain_label": "tailLiftBrand",
	"ui_label": "Tail lift brand",
	"TAIL_LIFT": [
		{
			"name": "Waltco",
			"value": "WALTCO",
			"sort": 0,
			"next": input2
		},
		{
			"name": "DEL",
			"value": "DEL",
			"sort": 1,
			"next": input2
		},
		{
			"name": "Zepro",
			"value": "ZEPRO",
			"sort": 2,
			"next": input2
		},
		{
			"name": "3rd Party",
			"value": "3RD_PARTY",
			"sort": 3,
			"next": input2
		}
	]
}

const machine_types = [
	{
		"name": "TMFL",
		"value": "TMFL",
		"next": input2
	},
	{
		"name": "Tail lift",
		"value": "TAIL_LIFT",
		"next": input1
	}
];

export default{
	machine_types,
	input1,
	input2,
	input3,
	input4
}