import React from 'react';

function ServiceDescription() {

  return (
   <div>
     <h3>Service description</h3>
     <br/>
     <p>HiConnect(tm) commissioning app is used to connect your Hiab equipment to the correct HiConnect account in order to use the HiConnect Service. With this HiConnect commissioning app, you can finalise the commissioning process between the equipment and HiConnect portal.</p>
   </div>     
  )
}

export default ServiceDescription