import React, { Component } from 'react';
import auth from '../../auth/authAWS'
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, HashRouter, BrowserRouter, NavLink, Redirect, withRouter } from 'react-router-dom';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    }
  }
  componentWillMount() {
    this.setState({ 'authenticated': auth.isSessionValid()})
  }
render() {
  let props = this.props
  return (
    <div>
      {this.state.authenticated === true ?
        (<PinCheck component={Component} {...props} />) : (<Redirect to={{ pathname: '/' }} />)
      }
    </div>
  )}
}

export default PrivateRoute

// PIN wrapper to check if PIN valid. Don't ask for pin in development.
const PinCheck = ({ component: Component, ...rest }) => (
  (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REQUIRE_PIN_IN_DEVELOPMENT === 'false') ? (
    <Route {...rest} render={(props) => (<Component {...props} />)} />
  ) : (
      <Route {...rest} render={(props) => (
        auth.pinVerifyStatus() === 'false'
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/pin' }} />
      )
      } />
    )
)