import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar'

loadProgressBar()

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

export default {
  // Axios common
  get (url, request) {
    return axios.get(url, request)
  },
  post (url, data, config) {
    return axios.post(url, data, config)
  },
  patch (url, request, config) {
    return axios.patch(url, request, config)
  },
  put (url, data, config) {
    return axios.put(url, data, config)
  },
  delete (url, request) {
    return axios.delete(url, request)
  },
  setAuthToken (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}
