import React, { Component } from 'react';
import auth from '../auth/authAWS'
import api from '../api/api'

class SignOut extends Component {
  componentDidMount(){
    const token = localStorage.getItem('refresh_token')
    
    // Call to revoke tokens from SalesForce
    api.post('auth/logout', {
      "token" : token
    })
    .then((data) => {
      console.log("Success logging out " + data)
    })
    .catch((error) => {
      console.log("Error logging out :/ " + error)
    })
    .finally(() => {
      console.log("Clear local storage")
      auth.logout()
  
      console.log("Send user to SalesForce logout")
      if (typeof window !== 'undefined') {
        window.location.href = process.env.REACT_APP_SALESFORCE_BASE_URL + '/services/auth/idp/oidc/logout'
      }
    })
  }

  render() {
    return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="circle-loader small">
          <div></div>
        </div>
      </div>
      <p className="mt-0 mb-0 text-center">Logging out</p>
    </div>)
  }
}

export default SignOut