import React, {Component} from 'react';
import Quagga from 'quagga';

class BarcodeReader extends Component {
 
  componentDidMount() {
    Quagga.init({
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        constraints: {
          width: 400,
          height: 400,
          facingMode: "environment"
        }
      },
      numOfWorkers: 0,
      decoder: {
        readers: ["code_128_reader"]
      },
      locate: false,
      frequency: 5
    }, function (err) {
      if (err) {
        console.log(err)
        return
      }
      Quagga.start()
    })
    Quagga.onDetected(this.onDetected)
  }

  componentWillUnmount() {
    Quagga.offDetected(this.onDetected)
    Quagga.stop()
  }

  onDetected = result => {
    this.props.handleEquipmentSerialNoScan(result.codeResult.code)
  }

  render() {
    return (
      <div>
        <div id="interactive" className="viewport" />
      </div>
    )
  }
}

export default BarcodeReader;