import React, { Component } from 'react';
import Modal from 'react-modal';
import api from '../../api/api';
import './ListItem.scss';
import unpairSvg from '../../assets/images/unpair.svg';
import { translate } from '../../util';
import connectingSvg from '../../assets/images/connecting.svg';
import failedSvg from '../../assets/images/failed.svg';
import successSvg from '../../assets/images/success.svg';

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      errorModalIsOpen: false,
      errorMessage: '',
      loadingState: '',
      activeModal: ''
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = (modalName) => {
    this.setState({ modalIsOpen: true, activeModal: modalName })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, activeModal: '' })
    this.props.getHistory()
  }

  openErrorModal = errorMessage => {
    this.setState({ errorModalIsOpen: true, errorMessage })
  }

  closeErrorModal = () => {
    this.setState({ errorModalIsOpen: false, errorMessage: '' })
  }

  unpair = () => {
    api.delete(`/commission/${this.props.item.equipmentSerialNumber}`).then(data => {
      this.props.displayStatusMessage('success')
    }).catch(error => {
      this.props.displayStatusMessage('error')
      this.openErrorModal('Unpairing failed: ' + error.response.request.response)
    })
  }

  unpairVolvo = () => {
    console.log('Unpair Volvo ' + this.props.item.gatewaySerialNumberWithoutPrefix);
    this.openModal('unpair-volvo')
    this.setState({ 'loadingState': 'unpair-volvo-loading' })
    localStorage.setItem('pairingInProgress', 'true')
    api.delete('/reset/' + this.props.item.gatewaySerialNumberWithoutPrefix).then(data => {
      this.pollStatus('GATEWAY_RESET_SUCCESS', () => {
        console.info('GATEWAY_RESET_SUCCESS received..')
        this.setState({ 'loadingState': 'unpair-volvo-success' })
        localStorage.setItem('pairingInProgress', 'false')
      })
    }).catch(error => {
      this.props.displayStatusMessage('error')
      this.closeModal()
      this.openErrorModal('Unpairing failed: ' + error)
      localStorage.setItem('pairingInProgress', 'false')
    })
  }

  pollStatus = (expectedState, successCb) => {
    api.get('/pair/' + this.props.item.gatewaySerialNumberWithoutPrefix).then((data) => {
      // User cancelled (closed the modal). Stop here. Do things. Don't panic.
      if (this.state.loadingState === '' || this.state.loadingState === 'unpair-error') {
        return false
      }
      let currentState = data.data.pairing.currentState.phase;
      console.log('Waiting for ' + expectedState + ' state..', 'Current: ' + currentState)
      if (currentState === expectedState) {
        successCb()
      } else {
        // If state has FAILURE. Stop here. Do things. Panic.
        if (currentState.includes('FAIL')) {
          console.warn(currentState + ' happened. Stop here.')
          this.setState({'loadingState': 'unpair-error'});
          this.closeModal()
          this.openErrorModal('Unpairing failed: ' + currentState)
          localStorage.setItem('pairingInProgress', 'false')
          this.props.getHistory()
          return false;
        }

        if (currentState.includes('TIMEOUT')) {
          console.warn(currentState + ' happened. Taking too long. Stop here.')
          this.setState({'loadingState': 'unpair-error'});
          this.closeModal()
          this.openErrorModal('Unpairing failed: ' + currentState)
          localStorage.setItem('pairingInProgress', 'false')
          this.props.getHistory()
          return false;
        }

        // Carry on. Keep calm.
        else {
          setTimeout(() => {
            this.pollStatus(expectedState, successCb)
          }, 3000)
        }
      }
    }).catch((error) => {
      this.setState({ 'loadingState': 'unpair-error' });
      this.closeModal()
      localStorage.setItem('pairingInProgress', 'false')
      this.openErrorModal('Unpairing failed: ' + error)
    })
  }

  render() {
    const item = this.props.item

    let options = {
      hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    const mappingTime = new Intl.DateTimeFormat('en-GB', options).format(new Date(item.mappingTime));
    const lastCommunication = new Intl.DateTimeFormat('en-GB', options).format(new Date(item.lastCommunication));

    return (
      <div className="item my-3 p-3 pb-4">
        <div className="commission">
          <div className="serial-number d-flex justify-content-between pb-2">
            <span className="text-center align-self-center w-100">{item.equipmentSerialNumber} - {item.gatewaySerialNumberWithoutPrefix}</span>
            <button type="submit" className="btn-delete d-flex align-items-center justify-content-center flex-row" onClick={() => { this.openModal('confirm-unpair') } }>
              <img src={unpairSvg} alt="Unpair" />
              <span>UNPAIR</span>
            </button>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <div className="commission-time d-flex flex-column col-md-7 col-sm-12 p-0 pr-3">
              <div><span className="font-weight-bold">Commissioned on: </span>{mappingTime}</div>
              <div><span className="font-weight-bold">Last active: </span>{lastCommunication}</div>
              <div><span className="font-weight-bold">Commissioner: </span>{item.username}</div>
              {item.isPairedWithVolvo &&
                <div><span className="font-weight-bold">Volvo PIN: </span>{item.volvoPairingPin}</div>
              }
            </div>
            <div className="commission-info d-flex flex-column col-md-5 col-sm-12 p-0">
              {item.machineType === 'TAIL_LIFT' &&
                <div><span className="font-weight-bold">Brand: </span>{translate(item.tailLiftBrand)}</div>
              }
              <div><span className="font-weight-bold">Input functionality: </span>{translate(item.inputFunctionality)}</div>
              {item.liftCapacity !== null &&
                <div><span className="font-weight-bold">Capacity: </span>{item.liftCapacity}</div>
              }
              {item.machineType === 'TAIL_LIFT' &&
                <div><span className="font-weight-bold">Temp. sensor: </span>{item.temperatureSensor ? "Yes" : "No"}</div>
              }
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen && this.state.activeModal === 'confirm-unpair' }
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Unpairing modal"
          className="Modal"
          overlayClassName="Overlay">
          <div className="header">
            <h3>Unpair commission</h3>
          </div>
          <div className="body">
            {item.isPairedWithVolvo ? (
              <p>Are you sure you want to unpair Volvo commission {item.gatewaySerialNumberWithoutPrefix}?</p>
            ) : (
              <p>Are you sure you want to unpair commission {item.equipmentSerialNumber} - {item.gatewaySerialNumberWithoutPrefix}?</p>
            )} 
          </div>
          <div className="footer">
            {item.isPairedWithVolvo ? (
              <button className="btn btn-primary" onClick={() => this.unpairVolvo()}>Confirm</button>
            ) : (
              <button className="btn btn-primary" onClick={() => this.unpair()}>Confirm</button>
            )}
            <button className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>   
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpen && this.state.activeModal === 'unpair'}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Unpairing modal"
          className="Modal"
          overlayClassName="Overlay">
          <div className="header">
            <img src={connectingSvg} alt="Unpair..." />
            <h3>Unpair commission</h3>
          </div>
          <div className="body">
            <p>Are you sure you want to unpair commission {item.equipmentSerialNumber} - {item.gatewaySerialNumberWithoutPrefix}?</p>
          </div>
          <div className="footer">
            <button className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
            <button className="btn btn-primary" onClick={() => this.unpair()}>Confirm</button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpen && this.state.activeModal === 'unpair-volvo'}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Unpairing modal"
          className="Modal"
          overlayClassName="Overlay">
          <div className="header">
            {this.state.loadingState !== 'unpair-volvo-success' && 
              <img src={connectingSvg} alt="Unpair..." />
            }
            {this.state.loadingState === 'unpair-volvo-success' && 
              <img className="no-pulse" src={successSvg} alt="Unpair..." />
            }
            <h3>Unpair VOLVO</h3>
          </div>
          {this.state.loadingState === 'unpair-volvo-loading' && 
            <div className="body text-center">
              <p>Unpairing Volvo from Gateway {item.gatewaySerialNumberWithoutPrefix}</p>
              <strong>Please wait...</strong>
            </div>
          }
          {this.state.loadingState === 'unpair-volvo-success' && 
            <div className="body text-center">
              <strong>Gateway {item.gatewaySerialNumberWithoutPrefix} successfully unpaired</strong>
            </div>
          }
          {this.state.loadingState === 'unpair-volvo-loading' && 
            <div className="footer">
              &nbsp;
            </div>
          }
          {this.state.loadingState === 'unpair-volvo-success' && 
            <div className="footer">
              <button className="btn btn-primary" onClick={this.closeModal}>Close</button>
            </div>
          }
        </Modal>

        <Modal
          isOpen={this.state.errorModalIsOpen}
          onRequestClose={this.closeErrorModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Error"
          className="Modal"
          overlayClassName="Overlay">
          <div className="error-header">
            <img src={failedSvg} alt="Error" />
            <h3>Error</h3>
          </div>
          <div className="error-body">
            <p>{this.state.errorMessage}</p>
          </div>
          <div className="error-footer">
            <button className="btn btn-secondary" onClick={this.closeErrorModal}>Ok</button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ListItem