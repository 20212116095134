import _ from 'lodash';
import input2 from './assets/data/input_new';

export function translate(value) {
  function fn(obj, key) {
    if (_.has(obj, key)) {
        return [obj];
     }
    return _.flatten(_.map(obj, function(v) {
        return typeof v == "object" ? fn(v, key) : [];
    }), true);
  }
  
  const names = fn(input2, 'name')
  return (_(names)
    .thru(function(coll) {
      return _.union(coll, _.map(coll, 'children'));
    })
    .flatten()
    .find({ value: value }) || { name: value })
    .name
}