import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import Modal from 'react-modal';
import api from '../../api/api';
import xregexp from 'xregexp';
import '../../style/accordion.scss';
import '../../style/loader.scss';
import '../../style/modal.scss';
import connectingSvg from '../../assets/images/connecting.svg';
import failedSvg from '../../assets/images/failed.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

Modal.setAppElement('#root')

class Volvo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: 'VOLVO',
      pincode: '',
      gatewaySerialNo: process.env.NODE_ENV === 'development' ? 'TSBI81014158' : '',
      gatewaySerialNoValid: null,
      pairingStatus: '',
      loadingState: 'pairing-started',
      scanMode: '' // 'manual'
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleScanMode = this.toggleScanMode.bind(this);
  }
  constraintsObj = {
    focusMode: 'continuous',
    exposureMode: 'continuous',
    whiteBalanceMode: 'continuous',
    torch: true,
    zoom: {
      exact: 1.50
    },
    facingMode: 'environment'
  }
  resetForm = () => {
    this.closeModal()
    this.setState({
      pincode: '',
      gatewaySerialNo: process.env.NODE_ENV === 'development' ? 'TSBI81014158' : '',
      gatewaySerialNoValid: null,
      pairingStatus: '',
      loadingState: ''
    });
    document.getElementById('accordion__heading-item-gateway-serial').click();
  }
  openModal = (state) => {
    console.log(this.state)
    this.setState({ modalIsOpen: true, loadingState: state })
    console.log(this.state)
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false, loadingState: '' })
  }
  handleGatewaySerialNoScan = data => {
    if (data) {
      this.setState({gatewaySerialNo: data.split(' ')[0], gatewaySerialNoValid: this.checkGatewaySerialNo(this.state.gatewaySerialNo)})
    }
  }
  handleGatewaySerialNoManually = event => {
    this.setState({ gatewaySerialNo: event.target.value, gatewaySerialNoValid: this.checkGatewaySerialNo(event.target.value)})
  }
  handlePinCode = event => {
    this.setState({ pincode: event.target.value })
  }
  checkGatewaySerialNo = serial => {
    // eslint-disable-next-line
    const regexp = xregexp("^[a-zA-Z]{4}[a-zA-Z0-9]{2}\\d{6}\$")
    return xregexp.test(serial, regexp)
  }
  toggleScanMode = (mode) => {
    this.setState({ scanMode: mode })
  }
  handleScanError = err => {
    console.error(err)
  }
  setLoading = loadingState => {
    this.setState({ loadingState: loadingState })
  }
  setPairingStatus = pairingStatus => {
    this.setState({ pairingStatus: pairingStatus })
  }
  cancelPairing = () => {
    localStorage.setItem('pairingInProgress', 'false')
    this.openModal('pairing-canceling')
    api.delete('/pair/' + this.state.gatewaySerialNo).then((data) => {
      console.log('Cancelling pairing' + this.state.gatewaySerialNo, data)
      this.pollPairingStatus('PAIRING_CANCELLED_SUCCESS', () => {
        console.log('Received PAIRING_CANCELLED_SUCCESS status...');
        this.resetForm();
      });
    }).catch((error) => {
      this.resetForm();
      console.log(error);
      localStorage.setItem('pairingInProgress', 'false')
    })
  }
  cancelPairingTestDevice = () => {
    api.delete('/pair/TSBI81014158').then((data) => {
      console.log('Succesfully cancelled pairing', data)
    })
  }
  resetTestDevice = () => {
    api.delete('/reset/TSBI81014158').then((data) => {
      console.log('Succesfully reset', data)
    })
  }
  checkTestDeviceStatus = () => {
    api.get('/pair/TSBI81014158').then((data) => {
      console.log('Test device status', data.data.pairing ? data.data.pairing.currentState.phase : '', data)
    })
  }
  startPairingPin = () => {
    console.log('Starting pairing with pin: ' + this.state.pincode + ', serial:' + this.state.gatewaySerialNo)
    this.openModal('pairing-pin-started')
    api.put('/pair/' + this.state.gatewaySerialNo + '/pin?pin=' + this.state.pincode).then((data) => {
      console.log('PIN pairing complete for ' + this.state.gatewaySerialNo)
      console.log('Start polling status update for PAIRING_SUCCESS...');
      this.pollPairingStatus('PAIRING_SUCCESS', () => {
        console.log('Received PAIRING_SUCCESS status...');
        this.openModal('pairing-success')
        this.setPairingStatus('PAIRING_SUCCESS')
        document.getElementById('accordion__heading-item-confirmation').click()
        this.closeModal()
        localStorage.setItem('pairingInProgress', 'false')
      });
    }).catch((error) => {
      this.openModal('pairing-pin-error')
      localStorage.setItem('pairingInProgress', 'false')
      console.log(error)
    })
  }
  startPairing = () => {
    console.log('Starting new pairing for ' + this.state.gatewaySerialNo)
    localStorage.setItem('pairingInProgress', 'true')
    this.openModal('pairing-started')
    api.post('/pair/' + this.state.gatewaySerialNo).then((data) => {
      console.log('Pairing complete for ' + this.state.gatewaySerialNo)
      console.log('Start polling status update for PAIRING_STARTED_SUCCESS...');
      this.pollPairingStatus('PAIRING_STARTED_SUCCESS', () => {
        console.log('Received PAIRING_STARTED_SUCCESS status...');
        this.openModal('pairing-started-success')
        this.setPairingStatus('PAIRING_STARTED_SUCCESS')
        document.getElementById('accordion__heading-item-pin-code').click()
        this.closeModal()
      });
    }).catch((error) => {
      this.openModal('pairing-error')
      localStorage.setItem('pairingInProgress', 'false')
      console.log(error)
    })
  }
  pollPairingStatus = (expectedState, successCb) => {
    api.get('/pair/' + this.state.gatewaySerialNo).then((data) => {
      // User cancelled (closed the modal). Stop here. Do things. Don't panic.
      if (this.state.loadingState === '' || this.state.loadingState === 'pairing-error') {
        return false
      }
      let currentState = data.data.pairing.currentState.phase;
      console.log('Waiting for ' + expectedState + ' state..', 'Current: ' + currentState)
      if (currentState === expectedState) {
        successCb()
      } else {
        // If state has FAILURE. Stop here. Do things. Panic.
        if (currentState.includes('FAIL')) {
          console.warn(currentState + ' happened. Stop here.')
          this.openModal('pairing-error');
          return false;
        }

        // If state has TIMEOUT. Stop here. Do things.
        if (currentState.includes('TIMEOUT')) {
          console.warn(currentState + ' happened. Taking too long. Stop here.')
          this.openModal('pairing-error');
          return false;
        }
        
        // Carry on. Keep calm.
        else {
          setTimeout(() => {
            this.pollPairingStatus(expectedState, successCb)
          }, 3000)
        }
      }
    }).catch((error) => {
      this.openModal('pairing-error')
    })
  }
  render() {
    return (
      <div className="">
        {process.env.NODE_ENV === 'development' &&
        <div>
          <button className="btn btn-warning btn-sm m-2" onClick={this.cancelPairingTestDevice}>Cancel pairing (pair/DELETE)</button>
          <button className="btn btn-warning btn-sm m-2" onClick={this.resetTestDevice}>Reset (reset/DELETE)</button>
          <button className="btn btn-warning btn-sm m-2" onClick={this.checkTestDeviceStatus}>Status (pair/GET)</button>
          <button className="btn btn-warning btn-sm m-2" onClick={this.resetForm}>Reset form</button>
        </div>
        }
        <Accordion preExpanded={['item-gateway-serial']} >
          <AccordionItem uuid="item-gateway-serial">
            <AccordionItemHeading>
              <AccordionItemButton>
                Gateway serial number
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex flex-row align-items-center">
                  <button className="btn btn-primary" onClick={() => this.toggleScanMode('qr-gateway')}>
                    SCAN QR-CODE
                  </button>
                  <p className="p-0 m-0 ml-3" onClick={(e) => this.toggleScanMode('manual-gateway')}>Enter manually</p>
                </div>

                {(this.state.scanMode === 'qr-gateway') && (
                  <div className="d-flex flex-column mt-4 col-md-9 px-0">
                    <QrReader
                      delay={250}
                      resolution={1600}
                      showViewFinder={false}
                      onError={this.handleScanError}
                      onScan={this.handleGatewaySerialNoScan}
                      constraints={this.constraintsObj}
                    />
                    <p>{this.state.gatewaySerialNo}</p>
                  </div>
                )}
                {(this.state.scanMode === 'manual-gateway') && (
                  <div>
                    <input type="text" id="code" className="form-control mt-4" placeholder="Enter serial number" onChange={this.handleGatewaySerialNoManually} value={this.state.gatewaySerialNo} />
                  </div>
                )}
                {this.state.gatewaySerialNoValid === false &&
                  <p className="text-danger">Serial number not valid</p>
                }
                {this.state.gatewaySerialNoValid === true &&
                  <p className="text-success">Serial number valid <span role="img" aria-label="ok">✅</span></p>
                }
                <button className="btn btn-primary mt-4 btn-hiab" disabled={!this.state.gatewaySerialNoValid} onClick={this.startPairing}>START PAIRING</button>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  shouldCloseOnOverlayClick={false}
                  contentLabel="Pairing modal"
                  className="Modal"
                  overlayClassName="Overlay">
                  {this.state.loadingState === 'pairing-started' &&
                  <div>
                    <div className="header">
                      <img src={connectingSvg} alt="Connecting..." />
                      <h3>Connecting</h3>
                    </div>
                    <div className="body">
                      <p className="text-center">Pairing to Volvo connected bodies.<br/>This may take some time..</p>
                    </div>
                    <div className="footer">
                      <button className="btn btn-primary" onClick={this.cancelPairing}>Cancel</button>
                    </div>
                  </div>
                  }
                  {this.state.loadingState === 'pairing-pin-started' &&
                  <div>
                    <div className="header">
                      <img src={connectingSvg} alt="Connecting..." />
                      <h3>Pairing</h3>
                    </div>
                    <div className="body">
                      <p className="text-center">Pairing Volvo and Gateway.</p>
                    </div>
                    <div className="footer">
                      <button className="btn btn-primary" onClick={this.cancelPairing}>Cancel</button>
                    </div>
                  </div>
                  }
                  {this.state.loadingState === 'pairing-error' && 
                  <div>
                    <div className="header">
                      <img src={failedSvg} alt="Error" />
                      <h3>Pairing failed</h3>
                    </div>
                    <div className="body">
                      <p className="text-center">Can not connect to gateway</p>
                    </div>
                    <div className="footer">
                      <button className="btn btn-primary" onClick={this.cancelPairing}>Cancel</button>
                      <button className="btn btn-primary" onClick={this.startPairing}>Restart pairing</button>
                    </div>
                  </div>
                  }
                  {this.state.loadingState === 'pairing-canceling' && 
                  <div>
                    <div className="header">
                      <img src={failedSvg} alt="Error" />
                      <h3>Canceling pairing</h3>
                    </div>
                    <div className="body">
                      <p className="text-center">Cancelling pairing... Please wait</p>
                    </div>
                    <div className="footer">
                    </div>
                  </div>
                  }
                </Modal>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="item-pin-code">
            <AccordionItemHeading>
              <AccordionItemButton>
                VOLVO pin code
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="d-flex flex-column align-items-start">
                <div>
                  <input type="number" id="pincode" className="form-control mt-4" placeholder="Enter pin code" onChange={this.handlePinCode} value={this.state.pincode} />
                </div>
                <button className="btn btn-primary mt-4 btn-hiab" disabled={this.state.pincode === '' || this.state.pincode.length !== 4 || this.state.pairingStatus !== 'PAIRING_STARTED_SUCCESS'} onClick={this.startPairingPin}>START PAIRING</button>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="item-confirmation" className="confirmation">
            <AccordionItemHeading>
              <AccordionItemButton>
                Pairing status
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {this.state.loadingState === '' &&
                <div className="confirmation">
                  <div>
                    <h4>Product type</h4>
                    <span>VOLVO</span>
                  </div>
                  <div>
                    <h4>Gateway serial number</h4>
                    <span>{this.state.gatewaySerialNo}</span>
                    {this.state.gatewaySerialNo === '' &&
                      <span className="text-danger">Please enter gateway serial number</span>}
                  </div>
                  <div>
                    <h4>VOLVO Pincode</h4>
                    <span>{this.state.pincode}</span>
                    {this.state.pincode === '' &&
                      <span className="text-danger">Please enter pincode</span>}
                  </div>
                  <div>
                    <h4>Status</h4>
                    <span>{this.state.input1}</span>
                    {this.state.pairingStatus === 'PAIRING_SUCCESS' &&
                      <strong className="text-success">Succesfully paired</strong>
                    }
                    {this.state.pairingStatus !== 'PAIRING_SUCCESS' &&
                      <strong className="text-danger">Pairing incomplete</strong>
                    }
                  </div>
                <button className="btn btn-primary mt-4 btn-hiab" disabled={this.state.pairingStatus !== 'PAIRING_SUCCESS'} onClick={this.resetForm}>OK</button>
                </div>
              }
              {this.state.loadingState === 'loading' &&
                <div className="d-flex justify-content-center">
                  <div className={"circle-loader" + (this.state.loadingState === 'complete' ? 'load-complete' : '')}>
                    <div className={"checkmark draw" + (this.state.loadingState === 'complete' ? 'done' : '')}></div>
                  </div>
                </div>
              }
              {this.state.loadingState === 'error' &&
                <div className="d-flex justify-content-center">
                  <strong>Something went wrong.</strong>
                </div>
              }
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  }
}

export default Volvo