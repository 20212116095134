import React, { Component } from 'react';
import logo from '../../assets/images/hiconnect-logo.svg';
import './Header.scss';

class HeaderNormal extends Component {
  render() {
    return (
      <div className="bg">
        <div className="bar">
          <img width="100" src={logo} className="hiconnect-logo mt-3 ml-4" alt="HiConnect logo" />
        </div>
        <h4 className="pt-4 pl-4">GATEWAY COMMISSIONING</h4>
      </div>
    )
  }
}

export default HeaderNormal