import React, { Component } from 'react';
import '../../style/accordion.scss';
import '../../style/loader.scss';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import EquipmentSN from './panels/EquipmentSN';
import GatewaySN from './panels/GatewaySN';
import Configuration from './panels/Configuration';
import Confirmation from './panels/Confirmation';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        type: props.type,
        input1: '',
        input2: '',
        liftCapacity: '',
        gatewaySerialNo: '',
        gatewaySerialNoValid: null,
        equipmentSerialNo: '',
        equipmentSerialNoValid: null,
        temp: false,
        loadingState: '' // loading, complete, error, ''
      }
    }
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  componentWillMount() {
    let state = this.state
    if (this.props.type === 'TAIL_LIFT') {
      state.form.input1 = localStorage.getItem("taillift_default_brand") || ''
      state.form.input2 = localStorage.getItem("taillift_default_model") || ''
      state.form.temp = localStorage.getItem("taillift_default_temp") === 'true'
    } else
      if (this.props.type === 'TMFL') {
        state.form.input1 = localStorage.getItem("tmfl_default_brand") || ''
      }
    this.setState(state)
  }

  handleTypeChange = event => {
    let state = this.state;
    state.form.type = event.target.value;
    this.setState(state);
  }

  setEquipmentSerialNo = (serial, valid) => {
    let state = this.state
    state.form.equipmentSerialNo = serial
    state.form.equipmentSerialNoValid = valid
    this.setState(state)
  }

  setGatewaySerialNo = (serial, valid) => {
    let state = this.state
    state.form.gatewaySerialNo = serial
    state.form.gatewaySerialNoValid = valid
    this.setState(state)
  }

  setConfigurationInputs = ({ input1, input2, input3, temp }) => {
    let state = this.state
    state.form.input1 = input1
    state.form.input2 = input2
    state.form.input3 = input3
    state.form.temp = temp
    this.setState(state)
  }

  render() {
    return (
      <div className="">
        <Accordion preExpanded={['item-gateway-serial']} >
          <AccordionItem uuid="item-gateway-serial">
            <AccordionItemHeading>
              <AccordionItemButton>
                Gateway serial number
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <GatewaySN setGatewaySerialNo={this.setGatewaySerialNo} type={this.state.form.type} gatewaySerialNo={this.state.form.gatewaySerialNo} gatewaySerialNoValid={this.state.form.gatewaySerialNoValid} />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="item-equipment-serial">
            <AccordionItemHeading>
              <AccordionItemButton>
                Equipment serial number
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <EquipmentSN setEquipmentSerialNo={this.setEquipmentSerialNo} type={this.state.form.type} equipmentSerialNo={this.state.form.equipmentSerialNo} equipmentSerialNoValid={this.state.form.equipmentSerialNoValid} />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="item-configure">
            <AccordionItemHeading>
              <AccordionItemButton>
                Configure inputs
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Configuration type={this.props.type} setConfigurationInputs={this.setConfigurationInputs} gatewaySerialNo={this.state.form.gatewaySerialNo} />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="confirmation" className="confirmation">
            <AccordionItemHeading>
              <AccordionItemButton>
                Confirmation
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Confirmation type={this.props.type} {...this.state.form} setGatewaySerialNo={this.setGatewaySerialNo} setEquipmentSerialNo={this.setEquipmentSerialNo} />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  }
}

export default Form