import React, { Component } from 'react';
import Modal from 'react-modal';
import api from '../api/api';
import List from '../components/List/List'

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      status: null,
      modalIsOpen: false,
      errorMessage: ''
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.getHistory()
  }

  displayStatusMessage = message => {
    if (message === 'success') {
      this.setState({
        status: 'Commission unpaired'
      })
      this.getHistory();
    } else {
      this.setState({ status: 'ERROR' })
    }
  }

  getHistory = () => {
    api.get('/search/my').then(data => {
      this.setState({ results: data.data.objects })
      if (data.data.objects.length === 0) {
        this.setState({ status: 'No history' })
      }
    }).catch(error => {
      this.setState({ status: 'ERROR' })
      this.openModal('Cannot get history: ' + error.response.request.response)
    })
  }

  openModal = errorMessage => {
    this.setState({ modalIsOpen: true, errorMessage })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessage: '' })
  }

  render() {
    return (
      <div>
        <div>
          <h3>History</h3>
          <p>{this.state.status}</p>
          <List list={this.state.results} displayStatusMessage={this.displayStatusMessage} getHistory={this.getHistory} />
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Error"
          className="Modal"
          overlayClassName="Overlay">
          <div className="error-header">
            <h3>Error</h3>
          </div>
          <div className="error-body">
            <p>{this.state.errorMessage}</p>
          </div>
          <div className="error-footer">
            <button className="btn btn-secondary" onClick={this.closeModal}>Ok</button>
          </div>
        </Modal>
      </div>
    )
  }

}

export default History