// import AWS from 'aws-sdk';
import sha from './sha'
import api from '../api/api'

var auth = {}

// Set app to offline if running in local dev. Authentication is skipped
if(process.env.REACT_APP_OFFLINE === 'true') {
  localStorage.setItem('access_token', 'offline')
  localStorage.setItem('refresh_token', 'offline')
}

export default {
  login() {
    var randomString = (length) => {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for(var i = 0; i < length; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    }
    // Consumer key for the connected app created in Salesforce
    // **** REPLACE WITH YOUR OWN CONSUMER KEY ****
    var client_id = process.env.REACT_APP_LOGIN_CLIENT_ID;

    // Callback URL (must be HTTPS) configured for the connected app      
    var redirect_uri = process.env.REACT_APP_SALESFORCE_CALLBACK; 
    // Construct the redirect URL
    // For getting an id token, response_type of 
    // "token id_token" (note the space), scope of 
    // "openid", and some value for nonce is required.
    // client_id must be the consumer key of the connected app.
    // redirect_uri must match the callback URL configured for 
    // the connected app.
    const salesforceAuthUrl = process.env.REACT_APP_SALESFORCE_BASE_URL +  '/services/oauth2/authorize'
    var url = salesforceAuthUrl
      + '?response_type=code'
      + '&scope=openid refresh_token'
      + '&nonce=' + randomString(20)
      + '&client_id=' + client_id
      + '&redirect_uri=' + redirect_uri
      + '&prompt=login'
      + '&display=touch';

    console.log(`Go for login at ${url}`)
    // Redirect the user to Salesforce
    window.location.replace(url);
  },
  logout() {
    localStorage.removeItem('pin');
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  },
  afterLogin() {
    // Capture the redirected URL
    var url = window.location.href;
    console.log(`After login: ${url}`)

    // Check if there was an error parameter
    var error = url.match('error=([^&]*)')
    if (error) {
      // If so, extract the error description and display it
      var description = url.match('error_description=([^&]*)')
      console.log('Error: ' + error[1] + '<br>Description: ' + description[1] + '</br>');
      return;
    }
   
    // Extract code token from the url parameter
    var match = url.match('code=([^&]*)');
    if (match) {
      var code = match[1]; // String captured by ([^&]*)

      // Make AWS request using the id token
      if (code) {
        console.log('After login: Code received', code)
        this.requestAccessToken(code)
      } else {
        console.log('Error: Could not retrieve id token from the URL');
      }
    } else {
      console.log('Error: There was no id token in the URL');
    }
  },
  // Poll the session and renew the tokens if needed. Force relogin if tokens are invalid.
  keepSessionAlive() {
    setInterval(() => {
      this.isLoggedIn().then((loggedin) => {
        if(loggedin) {
          console.info('Session OK')
        } else {
          console.warn('Session invalid. Force relogin..')
          window.location.assign('/');
        }
      })
    }, 30*1000)
  },
  async isLoggedIn() {
    api.setAuthToken(this.getAccessToken())
    console.log('Checking current login status with access_token: ' + this.getAccessToken())

    let authPromise = new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'development') {
        setTimeout( () => {
          if (this.getAccessToken() === 'offline') {
            resolve(true)
          } else {
            resolve(false)
          }
        }, 1500)
      } else {
        api.get('healthcheckauth')
        .then((response) => {
          console.log("Access token Ok")
          resolve(true)
        })
        .catch((error) => {
          console.log('Access token didn´t work. Check with refresh token')
          const refresh_token = this.getRefreshToken()
          api.post('auth/refresh', {
            "token": refresh_token
          })
          .then((data) => {
            console.log('auth/refresh data', data)
            api.setAuthToken(data.data.access_token)
            localStorage.setItem('access_token', data.data.access_token)
            resolve(true)
          })
          .catch((error) => {
            console.log("Error setting and receiving access token" + error)
            resolve(false)
          })
        })
      }
    })
    return await authPromise
  },
  // Dumb check if localstorage has a token
  isSessionValid() {
    if (this.getAccessToken() !== '' && this.getAccessToken() !== null) {
      return true
    }
    return false
  },
  getAccessToken() {
    return localStorage.getItem('access_token') || ""
  },
  getRefreshToken() {
    return localStorage.getItem('refresh_token') || ""
  },
  requestAccessToken(code) {
    api.post('auth/code', {
      "code" : code
    })
    .then((data) => {
      localStorage.setItem('access_token', data.data.access_token)
      localStorage.setItem('refresh_token', data.data.refresh_token)
      api.setAuthToken(data.data.access_token)
      window.location.replace("/pin")
    })
    .catch((error) => {
      console.log(error)
      window.location.replace("/") 
    })
  },
  isPinValid() {
    if (localStorage.getItem('pin')) {
      return true;
    }
    return false;   
  },
  getPin(pin) {
    // console.log(auth.username,pin)
    return sha.digestMessage(auth.username + pin).then(digestValue => {
      return sha.hexString(digestValue);
    });
  },
  pinVerifyStatus() {
    return localStorage.getItem('checkpin')
  },
  resetPin() {
    localStorage.removeItem('pin');
  }
}
