import React, {Component} from 'react';
import QrReader from 'react-qr-reader';
import xregexp from 'xregexp';

class GatewaySN extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scanMode: ''
    }
  }

  constraintsObj = {
    focusMode: 'continuous',
    exposureMode: 'continuous',
    whiteBalanceMode: 'continuous',
    torch: true,
    zoom: {
      ideal: 1.50
    },
    facingMode: 'environment'
  }

  toggleScanMode = mode => {
    this.setState({
      scanMode: mode
    })
  }

  handleScanError = err => {
    console.error(err)
  }

  handleGatewaySerialNoScan = data => {
    if (data) {
      var code = data.split(';')[0]
      // Remove 'SN ' -prefix if present
      if (code.includes('SN ')) {
        code = code.replace('SN ', '')
      }
      this.setSerial(code, this.checkGatewaySerialNo(code))
    }
  }

  handleGatewaySerialNoManually = event => {
    this.setSerial(event.target.value, this.checkGatewaySerialNo(event.target.value))
  }

  setSerial = (gatewaySerialNo, gatewaySerialNoValid) => {
    this.props.setGatewaySerialNo(gatewaySerialNo, gatewaySerialNoValid)
  }

  checkGatewaySerialNo = serial => {
    if (this.props.type === 'TAIL_LIFT') {
      // eslint-disable-next-line
      const regexp = xregexp("^(?=(?:.{6}|.{7}|.{15})$)\\d*$"); // 6, 7 OR 15
      return xregexp.test(serial, regexp);
    } else if (this.props.type === 'TMFL') {
      // eslint-disable-next-line
      const regexp = xregexp("^[a-zA-Z]{4}[a-zA-Z0-9]{2}\\d{6}\$")

      // Test for aplicom gateways in TMFL
      const regexp_aplicom = xregexp("^\\d{15}$")
      return xregexp.test(serial, regexp) || xregexp.test(serial, regexp_aplicom)
    }
  }

  render() {
    return (
      <div className="d-flex flex-column align-items-start">
        <div className="d-flex flex-row align-items-center">
          <button className="btn btn-primary" onClick={() => this.toggleScanMode('qr-gateway')}>
            SCAN QR-CODE
          </button>
          <p className="p-0 m-0 ml-3" onClick={(e) => this.toggleScanMode('manual-gateway')}>Enter manually</p>
        </div>

        {(this.state.scanMode === 'qr-gateway') && (
          <div className="d-flex flex-column mt-4 col-md-9 px-0">
            <QrReader
              delay={250}
              resolution={1600}
              showViewFinder={false}
              onError={this.handleScanError}
              onScan={this.handleGatewaySerialNoScan}
              constraints={this.constraintsObj}
            />
            <p>{this.props.gatewaySerialNo}</p>
          </div>
        )}
        {(this.state.scanMode === 'manual-gateway') && (
          <div>
            <input type="text" id="gateway-serial" className="form-control mt-4" placeholder="Enter serial number" onChange={this.handleGatewaySerialNoManually} value={this.props.gatewaySerialNo} />
          </div>
        )}
        {this.props.gatewaySerialNoValid === false &&
          <p className="text-danger">Serial number not valid</p>
        }
        {this.props.gatewaySerialNoValid === true &&
          <p className="text-success">Serial number valid <span role="img" aria-label="ok">✅</span></p>
        }
      </div>
    )
  }
}

export default GatewaySN