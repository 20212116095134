import React from 'react';
import ListItem from '../ListItem/ListItem'

function List(props) {

  return (
    <div>
      <ol className="list-unstyled">
        {props.list.map(item =>
          <li key={item.mappingIdentifier}><ListItem item={item} {...props} /></li>
        )}
      </ol>
    </div>
  )
}

export default List