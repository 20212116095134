import React, { Component } from 'react';
import _ from 'lodash';
import input from '../../../assets/data/input_new.js';

class Configuration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input1: '',
      input2: '',
      input3: '',
      temp: false
    }
  }

  componentWillMount() {
    if (this.props.type === 'TAIL_LIFT') {
      this.setState({
        input1: localStorage.getItem('taillift_default_brand') || '',
        input2: localStorage.getItem('taillift_default_model') || '',
        temp: localStorage.getItem('taillift_default_temp') === 'true'
      })
    } else if (this.props.type === 'TMFL') {
      this.setState({
        input1: localStorage.getItem('tmfl_default_brand') || ''
      })
    }
  }

  handleInput1Change = event => {
    let state = this.state;
    state.input1 = event.target.value;
    state.input2 = '';
    this.setState(state);
    this.props.setConfigurationInputs(state)
  }

  handleInput2Change = event => {
    let state = this.state;
    state.input2 = event.target.value;
    state.input3 = '';
    this.setState(state);
    this.props.setConfigurationInputs(state)
  }

  handleInput3Change = event => {
    let state = this.state;
    state.input3 = event.target.value;
    this.setState(state);
    this.props.setConfigurationInputs(state)
  }

  handleTempChange = event => {
    let state = this.state;
    state.temp = event.target.checked;
    this.setState(state);
    this.props.setConfigurationInputs(state)
  }

  buildInput1Options = () => {
    let arr = []
    if (this.props.type === 'TAIL_LIFT') {
      _.forEach(input.input1.TAIL_LIFT, (obj, key) => {
        arr.push(<option value={obj.value} key={'Input1' + key}>{obj.name}</option>)
      })
    }
    if (this.props.type === 'TMFL') {
      _.forEach(input.input2.TMFL, (obj, key) => {
        arr.push(<option value={obj.value} key={'Input2' + key}>{obj.name}</option>)
      })
    }
    return arr
  }
  buildInput2Options = () => {
    if (this.state.input1 === '') {
      return []
    }
    let arr = []
    if (this.props.type === 'TAIL_LIFT') {
      // Track unit serial length === 6 OR 7
      if (this.props.gatewaySerialNo.length === 6 || this.props.gatewaySerialNo.length === 7) {
        _.forEach(input.input2[this.state.input1].TRACKUNIT, (obj, key) => {
          arr.push(<option value={obj.value} key={'Input2' + key}>{obj.name}</option>)
        })
      }
      // Aplicom serial length === 15
      if (this.props.gatewaySerialNo.length === 15) {
        _.forEach(input.input2[this.state.input1].APLICOM_A9, (obj, key) => {
          arr.push(<option value={obj.value} key={'Input2' + key}>{obj.name}</option>)
        })
      }
    }
    return arr
  }
  buildInput3Options = () => {
    if (this.state.input2 === '') {
      return []
    }
    let arr = []
    let unit = input.input4[this.state.input1][this.state.input2].unit;
    // Track unit serial length === 15
    if (this.props.gatewaySerialNo.length === 15) {
      _.forEach(input.input4[this.state.input1][this.state.input2].values, (obj, key) => {
        arr.push(<option value={obj} key={'Input3' + key}>{obj} {unit}</option>)
      })
    }
    return arr
  }

  render() {
    return (
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="brand">Input1</label>
          <select className="custom-select" id="brand" value={this.state.input1} onChange={this.handleInput1Change}>
            <option value="" disabled>Select brand</option>
            {this.buildInput1Options()}
          </select>
        </div>
        {(this.props.type === 'TAIL_LIFT') && (
          <div>
            <div className="form-group">
              <label htmlFor="model">Input2</label>
              <select className="custom-select" id="model" value={this.state.input2} onChange={this.handleInput2Change}>
                <option value="" disabled>Select model</option>
                {this.buildInput2Options()}
              </select>
            </div>
            {(this.props.gatewaySerialNo.length === 15) &&
              <div className="form-group">
                <label htmlFor="model">Lift Capacity</label>
                <select className="custom-select" id="capacity" value={this.state.input3} onChange={this.handleInput3Change}>
                  <option value="" disabled>Select capacity</option>
                  {this.buildInput3Options()}
                </select>
              </div>
            }
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" id="temp" checked={this.state.temp} onChange={this.handleTempChange} />
              <label className="form-check-label" htmlFor="temp">Temperature sensor</label>
            </div>
          </div>)}
      </div>
    )
  }
}

export default Configuration