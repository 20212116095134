import React, { Component } from 'react';
import auth from './auth/authAWS' 
import api from './api/api'
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, HashRouter, BrowserRouter, NavLink, Redirect, withRouter } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import Home from './views/Home';
import HeaderNormal from './components/Header/HeaderNormal';
import HeaderSmall from './components/Header/HeaderSmall';
import NewComm from './views/NewComm';
import History from './views/History';
import Search from './views/Search';
import Profile from './views/Profile';
import HandleAuthCode from './views/HandleAuthCode'
import Pin from './views/Pin';
import SignOut from './views/SignOut'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'

import './App.scss';
import './style/typography.scss';
import './style/bootstrap-custom.scss';
import './style/loader.scss';

class App extends Component {
  constructor(props) {
    super(props)

    console.log('Current env (NODE_ENV): ' + process.env.NODE_ENV)
    console.log('Build time: ' + process.env.REACT_APP_BUILD_TIME)
    api.setAuthToken(auth.getAccessToken())
    auth.keepSessionAlive()

    // Check pin after the user leaves the UI (focus lost via visibilityState) IF in production OR in dev with REACT_APP_REQUIRE_PIN_IN_DEVELOPMENT set to 'true'
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REQUIRE_PIN_IN_DEVELOPMENT === 'true')) {
      document.addEventListener("visibilitychange", function () {
        // When UI is hidden set if pin check is needed.
        // Do not require pin check if Volvo pairing process is ongoing. (User left the UI during the process. Navigation would fail the process)
        if (document.visibilityState === 'hidden') {
          if (localStorage.getItem('pairingInProgress') === 'true') {
            localStorage.setItem('checkpin', 'false')
          } else {
            localStorage.setItem('checkpin', 'true')
          }
          localStorage.setItem('url', window.location.pathname)
        }
        if (document.visibilityState === 'visible') {
          // Check if session is valid. Proceed to pin check if ok. Force relogin if session is invalid
          auth.isLoggedIn().then((loggedin) => {
            if(loggedin) {
              console.info('Session OK after visibilityState = visible')
              // return to previous path if pin check required
              if (localStorage.getItem('checkpin') === 'true') {
                window.location.assign(localStorage.getItem('url'));
              }
            } else {
              console.warn('Session invalid after visibilityState = visible. Force relogin..')
              window.location.assign('/');
            }
          })
        }
      })
    }
  }
  render() {
    return (
      <div className="main container d-flex flex-lg-row flex-column col-xl-8 col-md-10 col-sm-12 no-gutters mx-auto">
        <Menu className="d-lg-none ml-n3">
          {auth.isSessionValid() &&
            <div>
              <NavLink to="/new">Commission new gateway</NavLink>
              <NavLink to="/history">History</NavLink>
              <NavLink to="/search">Search</NavLink>
              <NavLink to="/profile">User profile</NavLink>
            </div>
          }
        </Menu>
        <header className="header ml-4 ml-lg-n3 col-lg-4" id="header">
          <Route path="/" exact component={HeaderNormal} />
          <Route path="/pin/" exact component={HeaderSmall} />
          <Route path="/new/" component={HeaderSmall} />
          <Route path="/history/" exact component={HeaderSmall} />
          <Route path="/search/" exact component={HeaderSmall} />
          <Route path="/profile/" component={HeaderSmall} />
          <Route path="/api/auth/oauth-callback" exact component={HeaderSmall} />
          <Route path="/signout/" exact component={HeaderSmall} />
          <nav className="d-none d-lg-block">
            {auth.isSessionValid() &&
              <div>
                <NavLink to="/new">Commission new gateway</NavLink>
                <NavLink to="/history">History</NavLink>
                <NavLink to="/search">Search</NavLink>
                <NavLink to="/profile">User profile</NavLink>
              </div>
            }
          </nav>
        </header>
        <div className="content p-4 pr-lg-2 col-lg-8">
          <div className="col-12 px-0">
            <Route path="/" exact component={Home} />
            <Route path="/pin/" exact component={Pin} />
            <PrivateRoute path="/new/" component={NewComm} />
            <PrivateRoute path="/history/" component={History} />
            <PrivateRoute path="/search/" component={Search} />
            <PrivateRoute path="/profile/" component={Profile} />
            <Route path="/api/auth/oauth-callback" component={HandleAuthCode} />
            <Route path="/signout/" component={SignOut} />
          </div>
        </div>
    </div>
  )}
}

export default withRouter(App);
