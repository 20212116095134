import React, { Component } from 'react';
import Modal from 'react-modal';
import api from '../../../api/api';
import {translate} from '../../../util'

class Confirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingState: '',
      modal: {
        isOpen: false,
        type: '', // error, confirmation 
        message: '',
        headline: ''
      }
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = (message, type, headline) => {
    this.setState({
      modal: {
        isOpen: true,
        message: message,
        type: type,
        headline: headline
      }
    })
  }

  closeModal = () => {
    if (this.state.loadingState === 'error') {
      // Open 1. Accordion
      document.getElementById('accordion__heading-item-gateway-serial').click()
      this.setLoading('')
    }

    this.setState({
      modal: {
        isOpen: false,
        message: '',
        type: '',
        headline: ''
      }
    })
  }

  setLoading = loadingState => {
    this.setState({ loadingState })
  }

  resetForm = () => {
    document.getElementById('accordion__heading-item-gateway-serial').click()
    this.props.setGatewaySerialNo('', null)
    this.props.setEquipmentSerialNo('', null)
    this.setLoading('')
    console.log('resetForm')
  }
  
  setDefaultValues() {
    if (this.props.type === 'TAIL_LIFT') {
      localStorage.setItem('taillift_default_brand', this.props.input1)
      localStorage.setItem('taillift_default_model', this.props.input2)
      localStorage.setItem('taillift_default_temp', this.props.temp)
    } else
      if (this.props.type === 'TMFL') {
        localStorage.setItem('tmfl_default_brand', this.props.input1)
      }
  }

  sendCommission = () => {
    console.log('Sending new comm...')
    this.setLoading('loading')
    
    if (this.props.type === 'TAIL_LIFT') {
      api.post('/commission', {
        'equipmentSerialNumber': this.props.equipmentSerialNo,
        'gatewaySerialNumber': this.props.gatewaySerialNo,
        'equipmentType': this.props.type,
        'inputFunctionality': this.props.input2,
        'liftCapacity': this.props.input3,
        'tailLiftBrand': this.props.input1,
        'temperatureSensor': this.props.temp
      }).then((data) => {
        this.handleSuccess()
      }).catch((error) => {
        this.handleError(error)
      })
    } else
      if (this.props.type === 'TMFL') {
        api.post('/commission', {
          'equipmentSerialNumber': this.props.equipmentSerialNo,
          'gatewaySerialNumber': this.props.gatewaySerialNo,
          'equipmentType': this.props.type,
          'inputFunctionality': this.props.input1
        }).then((data) => {
          this.handleSuccess()
        }).catch((error) => {
          this.handleError(error)
        })
      }
  }

  handleSuccess = () => {
    console.log('Sending new comm OK...')
    this.setLoading('complete')
    this.openModal('Commission completed succesfully', 'confirmation', 'Success')
    this.setDefaultValues()
  }

  handleError = error => {
    this.openModal('Commission failed: ' + error.response.request.response, 'error', 'ERROR')
    this.setLoading('error')
  }

  render() {
    const formValid = () => {
      if (this.props.type === '') {
        return false
      }
      if (this.props.type === 'TAIL_LIFT') {
        if (this.props.gatewaySerialNo.length === 15) {
          return this.props.gatewaySerialNoValid &&
            this.props.equipmentSerialNoValid &&
            this.props.input1 !== '' &&
            this.props.input2 !== '' &&
            this.props.input3 !== '';
        } else {
          return this.props.gatewaySerialNoValid &&
            this.props.equipmentSerialNoValid &&
            this.props.input1 !== '' &&
            this.props.input1 !== '3RD_PARTY' && // 3rd party machines must always have Aplicom gateways => 15 digit serial 
            this.props.input2 !== '';
        }
      }
      if (this.props.type === 'TMFL') {
        return this.props.gatewaySerialNoValid &&
          this.props.equipmentSerialNoValid &&
          this.props.input1 !== ''
      }
    }

    return (
      <div>
        {(this.state.loadingState === '' || this.state.loadingState === 'complete') &&
          <div className="confirmation">
            <div>
              <h4>Product type</h4>
              {this.props.type === 'TAIL_LIFT' &&
                <span>Tail Lift</span>}
              {this.props.type === 'TMFL' &&
                <span>Truck mounted forklift</span>}
            </div>
            <div>
              <h4>Gateway serial</h4>
              <span>{this.props.gatewaySerialNo}</span>
              {this.props.gatewaySerialNo === '' &&
                <span className="text-danger">Please enter gateway serial number</span>}
            </div>
            <div>
              <h4>Equipment serial</h4>
              <span>{this.props.equipmentSerialNo}</span>
              {this.props.equipmentSerialNo === '' &&
                <span className="text-danger">Please enter equipment serial number</span>}
            </div>
            <div>
              <h4>Input 1</h4>
              <span>{translate(this.props.input1)}</span>
              {this.props.input1 === '' &&
                <span className="text-danger">Please select input 1 brand</span>}
            </div>
            {this.props.type === 'TAIL_LIFT' &&
              <div>
                <div>
                  <h4>Input 2</h4>
                  <span>{translate(this.props.input2)}</span>
                  {this.props.input2 === '' &&
                    <span className="text-danger">Please select input 2 model</span>}
                </div>
                {this.props.gatewaySerialNo.length === 15 &&
                  <div>
                    <h4>Lift capacity</h4>
                    <span>{this.props.input3}</span>
                    {this.props.input3 === '' &&
                      <span className="text-danger">Please select lift capacity</span>}
                  </div>
                }
                <div>
                  <h4>Temperature sensor</h4>
                  {(this.props.temp === true) ? (
                    <span>Temperature sensor: <b>Yes.</b></span>) : (
                      <span>Temperature sensor: <b>No.</b></span>
                    )}
                </div>
              </div>
            }
            {this.state.loadingState === '' &&
              <button className="btn btn-primary mt-4 btn-hiab" disabled={!formValid()} onClick={this.sendCommission}>SEND</button>
            }
            {this.state.loadingState === 'complete' &&
              <button className="btn btn-primary mt-4 btn-hiab" onClick={this.resetForm}>Pair another device</button>
            }
          </div>
        }
        {this.state.loadingState === 'loading' &&
          <div className="d-flex justify-content-center">
            <div className="circle-loader">
              <div className="checkmark draw"></div>
            </div>
          </div>
        }
        {this.state.loadingState === 'error' &&
          <div className="d-flex justify-content-center">
            <strong>Something went wrong.</strong>
          </div>
        }
        <Modal
          isOpen={this.state.modal.isOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Error"
          className="Modal"
          overlayClassName="Overlay">
          <div className="error-header">
            <h3>{this.state.modal.headline}</h3>
          </div>
          <div className="error-body">
            {this.state.loadingState === 'complete' && this.state.modal.type === 'confirmation' && 
              <div className="d-flex justify-content-center">
                <div className="circle-loader load-complete">
                  <div className="checkmark draw done"></div>
                </div>
              </div>
            }
            <p>{this.state.modal.message}</p>
          </div>
          <div className="error-footer">
            <button className="btn btn-secondary" onClick={this.closeModal}>Close</button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Confirmation