import React, { Component } from 'react';
import Pin from '../components/Pin/Pin';
import { withRouter } from 'react-router-dom'

class PinView extends Component {
  render() {
    return (
      <div className="d-flex flex-column mx-auto col-lg-6">
        <PinHistory />
      </div>
    )
  }
}

const PinHistory = withRouter(({ history }) => (
  <Pin history={history} />
))

export default PinView