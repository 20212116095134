import React, { Component } from 'react';
import auth from '../../auth/authAWS';
import ReactCodeInput from 'react-code-input';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import '../../../node_modules/react-code-input/styles/style.css'
import './Pin.scss'

class Pin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      pinValid: true
    };
    this.auth = auth;
    this.pinProps = {
      className: 'pinInput',
      inputStyle: {
        fontFamily: 'HelveticaNeue',
        margin: '.25em',
        MozAppearance: 'textfield',
        borderRadius: '0',
        fontSize: '2em',
        width: '1.5em',
        backgroundColor: 'white',
        border: '1px solid lightskyblue',
        textAlign: 'center'
      },
      inputStyleInvalid: {
        fontFamily: 'HelveticaNeue',
        margin: '.25em',
        MozAppearance: 'textfield',
        borderRadius: '0',
        fontSize: '2em',
        width: '1.5em',
        backgroundColor: 'white',
        textAlign: 'center',
        border: '1px solid red'
      }
    };
  }
  pinIsSet = () => {
    const pin = localStorage.getItem('pin');
    if (pin) {
      return true
    } else {
      return false
    }
  }
  getPin = (newpin) => {
    const pin = localStorage.getItem('pin');
    if (pin) {
      return pin
    } else {
      this.setPin()
    }
  }
  setPin = (e) => {
    if (e.length === 4) {
      auth.getPin(e).then((pinHash) => {
        // console.log(pinHash)
        localStorage.setItem('pin', pinHash);
        this.props.history.push('/new')
      })
    }
  }
  pinInput = (e) => {
    if(e.length === 4) {
      // console.log(e);
      auth.getPin(e).then((pinHash) => {
        if (pinHash === localStorage.getItem('pin')) {
          localStorage.setItem('checkpin', false)
          // Navigate to /new if previous path was '/pin', '/', null or '/api'. Otherwise use the path in localstorage
          let url = localStorage.getItem('url')
          this.props.history.push((url.includes('/pin') || url.includes('/api') || url === '/' || url === null) ? '/new' : localStorage.getItem('url'))
        } else {
          this.setState({pinValid: false });
        }
      })
    }
  }
  render() {
    return (
      <div className="d-flex flex-column justify-content-center">
        {!this.pinIsSet() &&
        <div className="d-flex flex-column align-items-center">
          <p className="text-center">Please set a 4 digit pin</p>
          <ReactCodeInput type="number" fields={4} {...this.pinProps} onChange={this.setPin} />
        </div>
        }
        {this.pinIsSet() &&
        <div className="d-flex flex-column align-items-center">
          <p className="text-center">Please enter pin</p>
          <ReactCodeInput isValid={this.state.pinValid} type="number" fields={4} {...this.pinProps} onChange={this.pinInput} />
          <span className="text-secondary text-center"><br/>Forgot your pin? Logout to reset it <br/>
            <NavLink to="/signout">
              <p className="text-center">LOG OUT</p>
            </NavLink>
          </span>
        </div>
        }
      </div>
    )
  }
}

export default Pin