import React, {Component} from 'react';
import Modal from 'react-modal';
import api from '../api/api';
import List from '../components/List/List';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      results: [],
      status: null,
      modalIsOpen: false,
      errorMessage: ''
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getSearchResults = () => {
    this.setState({status: ''})
    if (this.state.searchString.length > 0) {
      api.get(`search?serialNumber=${this.state.searchString}`).then(data => {
        this.setState({results: data.data})
        if (data.data.length === 0) {
          this.setState({status: 'No results'})
        }
      }).catch(error => {
        this.setState({status: 'ERROR'})
        this.openModal('Cannot get results: ' + error.response.request.response)
      })
    }
  }

  setSearchString = event => {
    this.setState({searchString: event.target.value})
  }

  displayStatusMessage = message => {
    message === 'success' ?
      this.setState({
        status: 'Commission unpaired',
        results: [],
        searchString: ''
      })
      :
      this.setState({status: 'ERROR'})
  }

  openModal = errorMessage => {
    this.setState({ modalIsOpen: true, errorMessage })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessage: '' })
  }

  render() {
    return (
      <div>
        <h3>Search</h3>
        <div className="d-flex">
          <input
            type="text"
            placeholder="Search: GW ID, Machine S/N"
            className="form-control mt-3"
            onChange={this.setSearchString}
            value={this.state.searchString} />

          <button className="btn btn-secondary btn-md mt-2" onClick={this.getSearchResults}>SEARCH</button>
        </div>

        <List list={this.state.results} displayStatusMessage={this.displayStatusMessage} />

        <p>{this.state.status}</p>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Error"
          className="Modal"
          overlayClassName="Overlay">
          <div className="error-header">
            <h3>Error</h3>
          </div>
          <div className="error-body">
            <p>{this.state.errorMessage}</p>
          </div>
          <div className="error-footer">
            <button className="btn btn-secondary" onClick={this.closeModal}>Ok</button>
          </div>
        </Modal>
      </div>
    )
  }

}

export default Search